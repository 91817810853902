<template>
  <ActionCard>
    <template v-slot:actions-trigger>
      <button class="btn btn-transparent btn-actions" @click="$emit('action')">
        <UiIcon v-if="!partner.isFetchByAccount" icon="more" />
      </button>
    </template>
    <template v-slot:middle>
      <UiIcon
        class="text-success"
        icon="tool_circle"
        width="130"
        height="130"
      />
    </template>
    <template v-slot:bottom v-if="!partner.isFetchByAccount">
      <span>Options</span>
    </template>
    <template v-else v-slot:bottom>
      <span class="background-no-data"><img class="svg-no-data" src="../assets/close-small.svg">Aucune donnée</span>
    </template>
  </ActionCard>
</template>
<script>
import { defineComponent } from "vue";
import ActionCard from "@/components/ui/action-card.vue";
export default defineComponent({
  emits: ["action"],
  props: {
    partner: {
      type: Object,
      required: true,
    },
  },
  components: {
    ActionCard,
  },
});
</script>
