<template>
  <ActionCard>
    <template v-slot:middle>
      <UiIcon
        v-if="'auto' === status"
        class="text-success"
        icon="renew-refresh"
        width="130"
        height="130"
      />
      <UiIcon
        v-if="'manual' === status"
        class="text-warning"
        icon="renew-calendar"
        width="130"
        height="130"
      />
    </template>
    <template v-slot:bottom>
      <span v-if="'auto' === status" class="text-center"
        >Renouvellement automatique</span
      >
      <span v-if="'manual' === status" class="text-center"
        >Renouvellement manuel</span
      >
    </template>
  </ActionCard>
</template>
<script>
import { defineComponent } from "vue";
import ActionCard from "@/components/ui/action-card.vue";
export default defineComponent({
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  components: {
    ActionCard,
  },
});
</script>
