<template>
  <ActionCard>
    <template v-slot:middle>
      <UiIcon icon="newspaper" width="100" height="100" />
    </template>
    <template v-if="!partner.isFetchByAccount" v-slot:bottom>
      <span>{{ partner.category }}</span>
    </template>
    <template v-else v-slot:bottom>
      <span class="background-no-data"><img class="svg-no-data" src="../assets/close-small.svg">Aucune donnée</span>
    </template>
  </ActionCard>
</template>
<script>
import { defineComponent } from "vue";
import ActionCard from "@/components/ui/action-card.vue";
export default defineComponent({
  props: {
    partner: {
      type: Object,
      required: true,
    },
  },
  components: {
    ActionCard,
  },
});
</script>
