<template>
  <div class="action-card card">
    <div class="card-body">
      <div class="card-top">
        <slot name="actions-trigger">
          <div class="btn-group" v-if="actions.length">
            <button
              class="btn btn-transparent btn-actions"
              @click="show = !show"
              @blur="show = false"
            >
              <UiIcon icon="more-dots" />
              <ul
                class="dropdown-menu"
                :class="{ show: show && actions.length }"
              >
                <li
                  class="dropdown-item"
                  v-for="action in actions"
                  :key="action"
                  @click="$emit('action', action)"
                >
                  {{ action }}
                </li>
              </ul>
            </button>
          </div>
        </slot>
      </div>
      <div class="card-middle">
        <slot name="middle" />
      </div>
      <div class="card-bottom">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["action"],
  props: {
    actions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: false,
    };
  },
});
</script>

<style lang="scss">
.action-card {
  .card-body {
    padding: 16px;
  }
  .card-top {
    margin-bottom: 16px;
  }
  .card-middle {
    margin-bottom: 16px;
  }
  .card-bottom {
    font-weight: 300;
    font-size: 18px;
    margin-top: 25px;
  }
  .card-top,
  .card-middle,
  .card-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-top {
    justify-content: flex-end;
  }
  .card-top {
    height: 32px;
  }
  .card-middle {
    height: 98px;
  }
  .card-bottom {
    height: 54px;
  }
  .btn-actions {
    height: 32px;
    width: 32px;
    padding: 0;
  }
  .dropdown-menu.show {
    left: 38px;
    top: -10px;
  }
  .background-no-data {
    background-color: rgba(198, 198, 198, 0.25);
    border-radius: 15px;
    padding: 5px;
  }
  .svg-no-data {
    width: 23px;
    margin-right: 5px;
    margin-bottom: 2px;
  }
}
</style>
