<template>
  <div class="row align-items-center py-5">
    <div class="col-4">
      <p class="text-dark fs-5 mb-0">
        Champ
        <span class="text-secondary">{{ selectedField.value }}</span> généré :
      </p>
    </div>
    <div class="col-8">
      <input type="text" class="form-control" disabled :value="generated" />
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    selectedField: {
      type: Object,
      required: true,
    },
    generated: {
      type: String,
      required: true,
    },
  },
});
</script>
