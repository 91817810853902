<template>
  <nav class="app-navbar bg-secondary">
    <div class="container">
      <div class="app-navbar-content row align-items-center">
        <div id="app-navbar-left-target" class="col"></div>
        <div class="col-auto text-center">
          <router-link to="/" class="app-navbar-brand"
            >MANAGER LOCAL.FR</router-link
          >
        </div>
        <div
          id="app-navbar-right-target"
          class="col d-flex justify-content-end"
        ></div>
      </div>
    </div>
  </nav>
</template>
<style lang="scss">
.app-navbar {
  .app-navbar-content {
    height: $app-navbar-height;
  }
  .app-navbar-brand {
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 18px;
    color: white;
  }
}
</style>
