<template>
  <div class="progress">
    <div
      class="progress-bar bg-primary"
      role="progressbar"
      :style="{ width: barWidth }"
    ></div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    min: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    max: {
      type: [String, Number],
      required: false,
      default: 100,
    },
    value: {
      type: [String, Number],
      required: false,
      default: 100,
    },
  },
  computed: {
    percentage() {
      return Math.floor((100 / +this.max) * +this.value);
    },
    barWidth() {
      return this.percentage + "%";
    },
  },
});
</script>
