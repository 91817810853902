<template>
  <div class="box">
    <h2>Aucune action disponible</h2>
    <p>
      Ce domaine n’est pas administrable par nos services.
      Il peut être géré par le partenaire ou par un prestataire externe.
      Pour connaître le propriétaire du domaine, effectuez un whois.
    </p>
  </div>
</template>

<script>
export default {
  name: "NoAction"
}
</script>

<style lang="scss" scoped>

.box {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  margin-top: 150px;

  h2 {
    color: #6C6C6C !important;
    font-size: 1.6em !important;
    text-align: center;
    margin-bottom: 10px;
  }
  p {
    color: #6C6C6C
  }
}
</style>
