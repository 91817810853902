<template>
  <ActionCard>
    <template v-slot:middle>
      <UiIcon class="text-success" icon="www" width="130" height="130" />
    </template>
    <template v-slot:bottom>
      <span>Sous-domaines</span>
    </template>
  </ActionCard>
</template>
<script>
import { defineComponent } from "vue";
import ActionCard from "@/components/ui/action-card.vue";
export default defineComponent({
  components: {
    ActionCard,
  },
});
</script>
