<template>
  <AppModal
    v-if="isDisplayed"
    class="partner-contact-modal"
    @close="$emit('isDisplayed', false)"
    :title="title"
    :icon="icon"
  >
    <div class="d-flex justify-content-between">
      <slot name="details"></slot>
    </div>
    <form @submit.prevent="$emit('inputData', dynamicModel)">
      <div class="form-group mb-3">
        <label class="label-code-auth">{{ inputLabel }}</label>
        <div class="d-flex justify-content-center">
          <input
            type="text"
            class="input-code-auth"
            v-model="dynamicModel"
          />
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="d-flex center info-code-auth">
          <UiIcon
            icon="info_circle"
            class="text-secondary me-2"
            width="32"
            height="32"
          />
          <slot name="info"></slot>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-3 display-buttons">
        <button
          class="btn btn-danger btn-outline mx-3"
          @click.prevent="$emit('isDisplayed', false)"
        >
          Annuler
        </button>
        <button
          type="submit"
          :disabled="!dynamicModel"
          :class="{disabled: !dynamicModel}"
          class="btn ui-button btn-secondary text-white btn-valid-localfr"
        >
          Valider
        </button>
      </div>
    </form>
  </AppModal>
</template>

<script>

export default {
  name: "ValidationModal",
  props: {
    isDisplayed: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    inputLabel: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    input: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      dynamicModel: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.info-code-auth {
  width: 70%;
  background-color: #F2B4411A;
  font-weight: 300;
  font-size: 13px;
}

.label-code-auth {
  color: $secondary;
}

.display-buttons > button.disabled {
  background: $icon-grey;
  border-color: $icon-grey;
  pointer-events: all;

  &:hover {
    cursor: not-allowed;
  }
}

.btn-valid-localfr {
  color: #fff !important;
  background-color: $secondary;
  border-color: $secondary;
  box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 8%) !important;
}

.grey-text {
  color: #6C6C6C;
}

.input-code-auth {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $secondary !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 35px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
}
</style>
