<template>
  <Teleport to="#app-navbar-right-target">
    <AppAuthStatus />
  </Teleport>
  <div class="container">
    <div class="row justify-content-center my-5 py-5">
      <div class="col-xl-6">
        <AppSearchInput class="app-search-input" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card p-3">
          <table class="table table-striped table-content-center">
            <thead>
              <th>Identifiant</th>
              <th>Raison sociale</th>
              <th></th>
              <th>Nom de domaine</th>
              <th>Contrat actif</th>
              <th></th>
            </thead>
            <tbody>
              <template v-for="result of results" :key="result.code">
                <tr>
                  <td>{{ result.code }}</td>
                  <td>{{ result.name }}</td>
                  <td><img v-if="result.isMainDomain" src="../assets/start.svg"></td>
                  <td>
                    <a
                      v-if="result.domain_url"
                      :href="`https://${result.domain_url}`"
                      target="_blank"
                      >{{ result.domain_url }}</a
                    >
                  </td>
                  <td>{{ result.category }}</td>
                  <td>
                    <template v-if="!isLoadingPartner">
                      <UiButton
                        @click="loadPartner(result)"
                        class="px-1 py-1 ui-button-icon"
                        icon-right="pencil"
                      />
                    </template>
                    <template v-else-if="domainId === result.domainId">
                      <div class="spinner-border text-secondary" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </template>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapper as partnersMapper } from "@/store/modules/partners";
import { mapper as uiMapper } from "@/store/modules/ui";
import AppSearchInput from "@/components/AppSearchInput.vue";
export default defineComponent({
  components: {
    AppSearchInput,
  },
  computed: {
    ...partnersMapper.mapState(["results", "partners"]),
    ...uiMapper.mapState(["isLoadingPartner"]),
  },
  data() {
    return {
      domainId: ''
    }
  },
  methods: {
    ...uiMapper.mapMutations(["setIsLoadingPartner"]),
    loadPartner(result) {
      this.setIsLoadingPartner(true);
      this.domainId = result.domainId;
      return this.$router.push({
        name: 'partner',
        params: { code: result.code },
        query: { domainId: result.domainId },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.spinner-border {
  width: 32px;
  height: 32px;
}
</style>
